define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-logs-show", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-logs", "@ember/controller"], function (_exports, _decorators, _computed, _customWizardLogs, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("hasLogs", "refreshing"), (_obj = {
    refreshing: false,
    hasLogs: (0, _computed.notEmpty)("logs"),
    page: 0,
    canLoadMore: true,
    logs: [],
    messageKey: "viewing",
    loadLogs() {
      if (!this.canLoadMore) {
        return;
      }
      const page = this.get("page");
      const wizardId = this.get("wizard.id");
      this.set("refreshing", true);
      _customWizardLogs.default.list(wizardId, page).then(result => {
        this.set("logs", this.logs.concat(result.logs));
      }).finally(() => this.set("refreshing", false));
    },
    noResults(hasLogs, refreshing) {
      return !hasLogs && !refreshing;
    },
    actions: {
      loadMore() {
        if (!this.loadingMore && this.logs.length < this.total) {
          this.set("page", this.page += 1);
          this.loadLogs();
        }
      },
      refresh() {
        this.setProperties({
          canLoadMore: true,
          page: 0,
          logs: []
        });
        this.loadLogs();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "noResults", [_dec], Object.getOwnPropertyDescriptor(_obj, "noResults"), _obj)), _obj)));
});