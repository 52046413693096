define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-api", ["exports", "discourse/lib/ajax", "discourse-common/utils/decorators", "@ember/object", "@ember/array"], function (_exports, _ajax, _decorators, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const CustomWizardApi = _object.default.extend((_dec = (0, _decorators.default)("name"), (_obj = {
    redirectUri(name) {
      let nameParam = name.toString().dasherize();
      const baseUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
      return baseUrl + `/admin/wizards/apis/${nameParam}/redirect`;
    }
  }, (_applyDecoratedDescriptor(_obj, "redirectUri", [_dec], Object.getOwnPropertyDescriptor(_obj, "redirectUri"), _obj)), _obj)));
  CustomWizardApi.reopenClass({
    create() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const api = this._super.apply(this);
      const authorization = params.authorization || {};
      const endpoints = params.endpoints;
      api.setProperties({
        name: params.name,
        title: params.title,
        originalTitle: params.title,
        authType: authorization.auth_type,
        authUrl: authorization.auth_url,
        tokenUrl: authorization.token_url,
        clientId: authorization.client_id,
        clientSecret: authorization.client_secret,
        username: authorization.username,
        password: authorization.password,
        authParams: (0, _array.A)(authorization.auth_params),
        authorized: authorization.authorized,
        accessToken: authorization.access_token,
        refreshToken: authorization.refresh_token,
        code: authorization.code,
        tokenExpiresAt: authorization.token_expires_at,
        tokenRefreshAt: authorization.token_refresh_at,
        endpoints: (0, _array.A)(endpoints),
        isNew: params.isNew,
        log: params.log
      });
      return api;
    },
    find(name) {
      return (0, _ajax.ajax)(`/admin/wizards/api/${name}`, {
        type: "GET"
      }).then(result => {
        return CustomWizardApi.create(result);
      });
    },
    list() {
      return (0, _ajax.ajax)("/admin/wizards/api", {
        type: "GET"
      }).then(result => {
        return result;
      });
    }
  });
  var _default = _exports.default = CustomWizardApi;
});