define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-similar-topics", ["exports", "@ember/component", "@ember/runloop", "discourse-common/utils/decorators"], function (_exports, _component, _runloop, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("topics"), (_obj = {
    classNames: ["wizard-similar-topics"],
    showTopics: true,
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(e) {
      if (this._state === "destroying") {
        return;
      }
      let $target = $(e.target);
      if (!$target.hasClass("show-topics")) {
        this.set("showTopics", false);
      }
    },
    toggleShowWhenTopicsChange() {
      this.set("showTopics", true);
    },
    actions: {
      toggleShowTopics() {
        this.set("showTopics", true);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleShowWhenTopicsChange", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleShowWhenTopicsChange"), _obj)), _obj)));
});