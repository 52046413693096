define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-step", ["exports", "@ember/object", "discourse/plugins/discourse-custom-wizard/discourse/mixins/valid-state", "discourse/lib/ajax", "discourse-common/utils/decorators", "@ember/runloop", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard"], function (_exports, _object, _validState, _ajax, _decorators, _runloop, _wizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj, _init;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _object.default.extend(_validState.default, (_dec = (0, _decorators.default)("wizardId", "id"), _dec2 = (0, _decorators.default)("i18nKey", "title"), _dec3 = (0, _decorators.default)("i18nKey", "description"), _dec4 = (0, _decorators.default)("index"), _dec5 = (0, _decorators.default)("fields.[]"), (_obj = {
    id: null,
    i18nKey(wizardId, stepId) {
      return `${wizardId}.${stepId}`;
    },
    translatedTitle(i18nKey, title) {
      return (0, _wizard.translationOrText)(`${i18nKey}.title`, title);
    },
    translatedDescription(i18nKey, description) {
      return (0, _wizard.translationOrText)(`${i18nKey}.description`, description);
    },
    displayIndex: index => index + 1,
    fieldsById(fields) {
      const lookup = {};
      fields.forEach(field => lookup[field.get("id")] = field);
      return lookup;
    },
    validate() {
      let allValid = true;
      this.fields.forEach(field => {
        allValid = allValid && field.check();
      });
      this.setValid(allValid);
    },
    fieldError(id, description) {
      const field = this.fields.findBy("id", id);
      if (field) {
        field.setValid(false, description);
      }
    },
    save() {
      const wizardId = this.get("wizardId");
      const fields = {};
      this.get("fields").forEach(f => {
        if (f.type !== "text_only") {
          fields[f.id] = f.value;
        }
      });
      return (0, _ajax.ajax)({
        url: `/w/${wizardId}/steps/${this.get("id")}`,
        type: "PUT",
        data: {
          fields
        }
      }).catch(response => {
        if (response.jqXHR) {
          response = response.jqXHR;
        }
        if (response && response.responseJSON && response.responseJSON.errors) {
          let wizardErrors = [];
          response.responseJSON.errors.forEach(err => {
            if (err.field === wizardId) {
              wizardErrors.push(err.description);
            } else if (err.field) {
              this.fieldError(err.field, err.description);
            } else if (err) {
              wizardErrors.push(err);
            }
          });
          if (wizardErrors.length) {
            this.handleWizardError(wizardErrors.join("\n"));
          }
          this.animateInvalidFields();
          throw response;
        }
        if (response && response.responseText) {
          const responseText = response.responseText;
          const start = responseText.indexOf(">") + 1;
          const end = responseText.indexOf("plugins");
          const message = responseText.substring(start, end);
          this.handleWizardError(message);
          throw message;
        }
      });
    },
    handleWizardError(message) {
      this.set("message", {
        state: "error",
        text: message
      });
      (0, _runloop.later)(() => this.set("message", null), 6000);
    }
  }, (_applyDecoratedDescriptor(_obj, "i18nKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "i18nKey"), _obj), _applyDecoratedDescriptor(_obj, "translatedTitle", [_dec2], Object.getOwnPropertyDescriptor(_obj, "translatedTitle"), _obj), _applyDecoratedDescriptor(_obj, "translatedDescription", [_dec3], Object.getOwnPropertyDescriptor(_obj, "translatedDescription"), _obj), _applyDecoratedDescriptor(_obj, "displayIndex", [_dec4], (_init = Object.getOwnPropertyDescriptor(_obj, "displayIndex"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "fieldsById", [_dec5], Object.getOwnPropertyDescriptor(_obj, "fieldsById"), _obj)), _obj)));
});