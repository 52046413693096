define("discourse/plugins/discourse-custom-wizard/discourse/components/similar-topics-validator", ["exports", "discourse/plugins/discourse-custom-wizard/discourse/components/validator", "discourse-common/lib/object", "discourse-common/utils/decorators", "@ember/runloop", "@ember/array", "@ember/object", "@ember/object/computed", "discourse/helpers/category-link", "@ember/string"], function (_exports, _validator, _object, _decorators, _runloop, _array, _object2, _computed, _categoryLink, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _validator.default.extend((_dec = (0, _decorators.default)("validation.categories"), _dec2 = (0, _decorators.default)("validationCategories"), _dec3 = (0, _decorators.default)("loading", "showSimilarTopics", "showNoSimilarTopics", "insufficientCharacters", "insufficientCharactersCategories"), _dec4 = (0, _decorators.default)("currentState"), _dec5 = (0, _decorators.default)("currentState"), _dec6 = (0, _decorators.observes)("field.value"), (_obj = {
    classNames: ["similar-topics-validator"],
    similarTopics: null,
    hasInput: (0, _computed.notEmpty)("field.value"),
    hasSimilarTopics: (0, _computed.notEmpty)("similarTopics"),
    hasNotSearched: (0, _computed.equal)("similarTopics", null),
    noSimilarTopics: (0, _object2.computed)("similarTopics", function () {
      return this.similarTopics !== null && this.similarTopics.length === 0;
    }),
    showSimilarTopics: (0, _object2.computed)("typing", "hasSimilarTopics", function () {
      return this.hasSimilarTopics && !this.typing;
    }),
    showNoSimilarTopics: (0, _object2.computed)("typing", "noSimilarTopics", function () {
      return this.noSimilarTopics && !this.typing;
    }),
    hasValidationCategories: (0, _computed.notEmpty)("validationCategories"),
    insufficientCharacters: (0, _object2.computed)("typing", "field.value", function () {
      return this.hasInput && this.field.value.length < 5 && !this.typing;
    }),
    insufficientCharactersCategories: (0, _computed.and)("insufficientCharacters", "hasValidationCategories"),
    validationCategories(categoryIds) {
      if (categoryIds) {
        return categoryIds.map(id => this.site.categoriesById[id]);
      }
      return (0, _array.A)();
    },
    catLinks(categories) {
      return categories.map(category => (0, _categoryLink.categoryBadgeHTML)(category)).join("");
    },
    currentState(loading, showSimilarTopics, showNoSimilarTopics, insufficientCharacters, insufficientCharactersCategories) {
      switch (true) {
        case loading:
          return "loading";
        case showSimilarTopics:
          return "results";
        case showNoSimilarTopics:
          return "no_results";
        case insufficientCharactersCategories:
          return "insufficient_characters_categories";
        case insufficientCharacters:
          return "insufficient_characters";
        default:
          return false;
      }
    },
    currentStateClass(currentState) {
      if (currentState) {
        return `similar-topics-${(0, _string.dasherize)(currentState)}`;
      }
      return "similar-topics";
    },
    currentStateKey(currentState) {
      if (currentState) {
        return `realtime_validations.similar_topics.${currentState}`;
      }
      return false;
    },
    validate() {},
    customValidate() {
      const field = this.field;
      if (!field.value) {
        this.set("similarTopics", null);
        return;
      }
      const value = field.value;
      this.set("typing", true);
      const lastKeyUp = new Date();
      this._lastKeyUp = lastKeyUp;

      // One second from now, check to see if the last key was hit when
      // we recorded it. If it was, the user paused typing.
      (0, _runloop.cancel)(this._lastKeyTimeout);
      this._lastKeyTimeout = (0, _runloop.later)(() => {
        if (lastKeyUp !== this._lastKeyUp) {
          return;
        }
        this.set("typing", false);
        if (value && value.length < 5) {
          this.set("similarTopics", null);
          return;
        }
        this.updateSimilarTopics();
      }, 1000);
    },
    updateSimilarTopics() {
      this.set("similarTopics", null);
      this.set("updating", true);
      this.backendValidate({
        title: this.get("field.value"),
        categories: this.get("validation.categories"),
        time_unit: this.get("validation.time_unit"),
        time_n_value: this.get("validation.time_n_value")
      }).then(result => {
        const similarTopics = (0, _array.A)((0, _object.deepMerge)(result["topics"], result["similar_topics"]));
        similarTopics.forEach(function (topic, index) {
          similarTopics[index] = _object2.default.create(topic);
        });
        this.set("similarTopics", similarTopics);
      }).finally(() => this.set("updating", false));
    },
    actions: {
      closeMessage() {
        this.set("showMessage", false);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "validationCategories", [_dec], Object.getOwnPropertyDescriptor(_obj, "validationCategories"), _obj), _applyDecoratedDescriptor(_obj, "catLinks", [_dec2], Object.getOwnPropertyDescriptor(_obj, "catLinks"), _obj), _applyDecoratedDescriptor(_obj, "currentState", [_dec3], Object.getOwnPropertyDescriptor(_obj, "currentState"), _obj), _applyDecoratedDescriptor(_obj, "currentStateClass", [_dec4], Object.getOwnPropertyDescriptor(_obj, "currentStateClass"), _obj), _applyDecoratedDescriptor(_obj, "currentStateKey", [_dec5], Object.getOwnPropertyDescriptor(_obj, "currentStateKey"), _obj), _applyDecoratedDescriptor(_obj, "customValidate", [_dec6], Object.getOwnPropertyDescriptor(_obj, "customValidate"), _obj)), _obj)));
});