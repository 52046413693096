define("discourse/plugins/discourse-custom-wizard/discourse/mixins/subscription", ["exports", "@ember/object/mixin", "@ember/application", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _mixin, _application, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const PRODUCT_PAGE = "https://custom-wizard.pavilion.tech/pricing";
  const SUPPORT_MESSAGE = "https://coop.pavilion.tech/new-message?username=support&title=Custom%20Wizard%20Support";
  const MANAGER_CATEGORY = "https://pavilion.tech/products/discourse-custom-wizard-plugin/support";
  var _default = _exports.default = _mixin.default.create((_dec = (0, _decorators.default)("subscriptionClientInstalled"), _dec2 = (0, _decorators.default)("subscriptionType"), (_obj = {
    subscriptionLandingUrl: PRODUCT_PAGE,
    subscriptionClientUrl: "/admin/plugins/subscription-client",
    adminWizards() {
      return (0, _application.getOwner)(this).lookup("controller:admin-wizards");
    },
    subscribed: (0, _computed.readOnly)("adminWizards.subscribed"),
    subscriptionType: (0, _computed.readOnly)("adminWizards.subscriptionType"),
    businessSubscription: (0, _computed.readOnly)("adminWizards.businessSubscription"),
    communitySubscription: (0, _computed.readOnly)("adminWizards.communitySubscription"),
    standardSubscription: (0, _computed.readOnly)("adminWizards.standardSubscription"),
    subscriptionAttributes: (0, _computed.readOnly)("adminWizards.subscriptionAttributes"),
    subscriptionClientInstalled: (0, _computed.readOnly)("adminWizards.subscriptionClientInstalled"),
    subscriptionLink(subscriptionClientInstalled) {
      return subscriptionClientInstalled ? this.subscriptionClientUrl : this.subscriptionLandingUrl;
    },
    subscriptionCtaLink(subscriptionType) {
      switch (subscriptionType) {
        case "none":
          return PRODUCT_PAGE;
        case "standard":
          return SUPPORT_MESSAGE;
        case "business":
          return SUPPORT_MESSAGE;
        case "community":
          return MANAGER_CATEGORY;
        default:
          return PRODUCT_PAGE;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "adminWizards", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "adminWizards"), _obj), _applyDecoratedDescriptor(_obj, "subscriptionLink", [_dec], Object.getOwnPropertyDescriptor(_obj, "subscriptionLink"), _obj), _applyDecoratedDescriptor(_obj, "subscriptionCtaLink", [_dec2], Object.getOwnPropertyDescriptor(_obj, "subscriptionCtaLink"), _obj)), _obj)));
});