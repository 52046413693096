define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-date-time-input", ["exports", "discourse/components/date-time-input", "discourse-common/utils/decorators"], function (_exports, _dateTimeInput, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _dateTimeInput.default.extend((_dec = (0, _decorators.default)("timeFirst", "tabindex"), _dec2 = (0, _decorators.default)("timeFirst", "tabindex"), (_obj = {
    classNameBindings: ["fieldClass"],
    timeTabindex(timeFirst, tabindex) {
      return timeFirst ? tabindex : tabindex + 1;
    },
    dateTabindex(timeFirst, tabindex) {
      return timeFirst ? tabindex + 1 : tabindex;
    }
  }, (_applyDecoratedDescriptor(_obj, "timeTabindex", [_dec], Object.getOwnPropertyDescriptor(_obj, "timeTabindex"), _obj), _applyDecoratedDescriptor(_obj, "dateTabindex", [_dec2], Object.getOwnPropertyDescriptor(_obj, "dateTabindex"), _obj)), _obj)));
});