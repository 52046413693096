define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-no-access", ["exports", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard", "discourse-common/utils/decorators", "@ember/component", "@ember/string", "discourse-common/lib/get-url"], function (_exports, _customWizard, _decorators, _component, _string, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("reason"), (_obj = {
    classNameBindings: [":wizard-no-access", "reasonClass"],
    reasonClass(reason) {
      return (0, _string.dasherize)(reason);
    },
    siteName() {
      return this.siteSettings.title || "";
    },
    actions: {
      skip() {
        if (this.currentUser) {
          _customWizard.default.skip(this.get("wizardId"));
        } else {
          window.location = (0, _getUrl.default)("/");
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "reasonClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "reasonClass"), _obj), _applyDecoratedDescriptor(_obj, "siteName", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "siteName"), _obj)), _obj)));
});