define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-manager", ["exports", "@ember/controller", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-manager", "@ember/array", "I18n", "@ember/string"], function (_exports, _controller, _decorators, _computed, _customWizardManager, _array, _I18n, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.observes)("importing", "destroying"), (_obj = {
    messageUrl: "https://pavilion.tech/products/discourse-custom-wizard-plugin/documentation/wizard-manager",
    messageKey: "info",
    messageIcon: "info-circle",
    messageClass: "info",
    importDisabled: (0, _computed.empty)("file"),
    exportWizards: (0, _array.A)(),
    destroyWizards: (0, _array.A)(),
    exportDisabled: (0, _computed.empty)("exportWizards"),
    destoryDisabled: (0, _computed.empty)("destroyWizards"),
    setMessage(type, key) {
      let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let items = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      this.setProperties({
        messageKey: key,
        messageOpts: opts,
        messageType: type,
        messageItems: items
      });
      setTimeout(() => {
        this.setProperties({
          messageKey: "info",
          messageOpts: null,
          messageType: null,
          messageItems: null
        });
      }, 10000);
    },
    buildWizardLink(wizard) {
      let html = `<a href='/admin/wizards/wizard/${wizard.id}'>${wizard.name}</a>`;
      html += `<span class='action'>${_I18n.default.t("admin.wizard.manager.imported")}</span>`;
      return {
        icon: "check-circle",
        html
      };
    },
    buildDestroyedItem(destroyed) {
      let html = `<span data-wizard-id="${destroyed.id}">${destroyed.name}</span>`;
      html += `<span class='action'>${_I18n.default.t("admin.wizard.manager.destroyed")}</span>`;
      return {
        icon: "check-circle",
        html
      };
    },
    buildFailureItem(failure) {
      return {
        icon: "times-circle",
        html: `${failure.id}: ${failure.messages}`
      };
    },
    clearFile() {
      this.setProperties({
        file: null,
        filename: null
      });
      document.getElementById("custom-wizard-file-upload").value = "";
    },
    setLoadingMessages() {
      if (this.importing) {
        this.setMessage("loading", "importing");
      }
      if (this.destroying) {
        this.setMessage("loading", "destroying");
      }
    },
    actions: {
      upload() {
        document.getElementById("custom-wizard-file-upload").click();
      },
      clearFile() {
        this.clearFile();
      },
      setFile(event) {
        let maxFileSize = 512 * 1024;
        const file = event.target.files[0];
        if (file === undefined) {
          this.set("file", null);
          return;
        }
        if (maxFileSize < file.size) {
          this.setMessage("error", "file_size_error");
          this.set("file", null);
          document.getElementById("custom-wizard-file-upload").value = "";
        } else {
          this.setProperties({
            file,
            filename: file.name
          });
        }
      },
      selectWizard(event) {
        const type = event.target.classList.contains("export") ? "export" : "destroy";
        const wizards = this.get(`${type}Wizards`);
        const checked = event.target.checked;
        let wizardId = event.target.closest("tr").getAttribute("data-wizard-id");
        if (wizardId) {
          wizardId = (0, _string.underscore)(wizardId);
        } else {
          return false;
        }
        if (checked) {
          wizards.addObject(wizardId);
        } else {
          wizards.removeObject(wizardId);
        }
      },
      import() {
        const file = this.get("file");
        if (!file) {
          this.setMessage("error", "no_file");
          return;
        }
        let $formData = new FormData();
        $formData.append("file", file);
        this.set("importing", true);
        this.setMessage("loading", "importing");
        _customWizardManager.default.import($formData).then(result => {
          if (result.error) {
            this.setMessage("error", "server_error", {
              message: result.error
            });
          } else {
            this.setMessage("success", "import_complete", {}, result.imported.map(imported => {
              return this.buildWizardLink(imported);
            }).concat(result.failures.map(failure => {
              return this.buildFailureItem(failure);
            })));
            if (result.imported.length) {
              this.get("wizards").addObjects(result.imported);
            }
          }
          this.clearFile();
        }).finally(() => {
          this.set("importing", false);
        });
      },
      export() {
        const exportWizards = this.get("exportWizards");
        if (!exportWizards.length) {
          this.setMessage("error", "none_selected");
        } else {
          _customWizardManager.default.export(exportWizards);
          exportWizards.clear();
          $("input.export").prop("checked", false);
        }
      },
      destroy() {
        let destroyWizards = this.get("destroyWizards");
        if (!destroyWizards.length) {
          this.setMessage("error", "none_selected");
        } else {
          this.set("destroying", true);
          _customWizardManager.default.destroy(destroyWizards).then(result => {
            if (result.error) {
              this.setMessage("error", "server_error", {
                message: result.error
              });
            } else {
              this.setMessage("success", "destroy_complete", {}, result.destroyed.map(destroyed => {
                return this.buildDestroyedItem(destroyed);
              }).concat(result.failures.map(failure => {
                return this.buildFailureItem(failure);
              })));
              if (result.destroyed.length) {
                const destroyedIds = result.destroyed.map(d => d.id);
                destroyWizards = this.get("destroyWizards");
                const wizards = this.get("wizards");
                wizards.removeObjects(wizards.filter(w => {
                  return destroyedIds.includes(w.id);
                }));
                destroyWizards.removeObjects(destroyedIds);
              }
            }
          }).finally(() => {
            this.set("destroying", false);
          });
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "setLoadingMessages", [_dec], Object.getOwnPropertyDescriptor(_obj, "setLoadingMessages"), _obj)), _obj)));
});