define("discourse/plugins/discourse-custom-wizard/discourse/routes/custom-wizard-step", ["exports", "I18n", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard", "@ember/routing/route", "discourse/mixins/scroll-top", "@ember/object", "@ember/service"], function (_exports, _I18n, _customWizard, _route, _scrollTop, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _route.default.extend((_obj = {
    router: (0, _service.inject)(),
    beforeModel() {
      const wizard = (0, _customWizard.getCachedWizard)();
      this.set("wizard", wizard);
      if (!wizard || !wizard.permitted || wizard.completed) {
        this.router.replaceWith("customWizard");
      }
    },
    model(params) {
      const wizard = this.wizard;
      if (wizard && wizard.steps) {
        const step = wizard.steps.findBy("id", params.step_id);
        return step ? step : wizard.steps[0];
      } else {
        return wizard;
      }
    },
    afterModel(model) {
      if (model.completed) {
        return this.router.transitionTo("wizard.index");
      }
      return model.set("wizardId", this.wizard.id);
    },
    setupController(controller, model) {
      let props = {
        step: model,
        wizard: this.wizard
      };
      if (!model.permitted) {
        props["stepMessage"] = {
          state: "not-permitted",
          text: model.permitted_message || _I18n.default.t("wizard.step_not_permitted")
        };
        if (model.index > 0) {
          props["showReset"] = true;
        }
      }
      controller.setProperties(props);
    },
    didTransition() {
      (0, _scrollTop.scrollTop)();
      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "didTransition", [_object.action], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj)), _obj));
});