define("discourse/plugins/discourse-custom-wizard/discourse/controllers/custom-wizard-index", ["exports", "@ember/controller", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _controller, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const reasons = {
    noWizard: "none",
    requiresLogin: "requires_login",
    notPermitted: "not_permitted",
    completed: "completed"
  };
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("noAccessReason"), (_obj = {
    noAccess: (0, _computed.or)("noWizard", "requiresLogin", "notPermitted", "completed"),
    noAccessI18nKey(reason) {
      return reason ? `wizard.${reasons[reason]}` : "wizard.none";
    },
    noAccessReason() {
      return Object.keys(reasons).find(reason => this.get(reason));
    }
  }, (_applyDecoratedDescriptor(_obj, "noAccessI18nKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "noAccessI18nKey"), _obj), _applyDecoratedDescriptor(_obj, "noAccessReason", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "noAccessReason"), _obj)), _obj)));
});