define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-text-field", ["exports", "discourse-common/utils/decorators", "discourse/lib/text-direction", "I18n", "discourse/components/text-field"], function (_exports, _decorators, _textDirection, _I18n, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _textField.default.extend((_dec = (0, _decorators.default)("placeholderKey"), (_obj = {
    attributeBindings: ["autocorrect", "autocapitalize", "autofocus", "maxLength", "dir"],
    dir() {
      if (this.siteSettings.support_mixed_text_direction) {
        let val = this.value;
        if (val) {
          return (0, _textDirection.isRTL)(val) ? "rtl" : "ltr";
        } else {
          return (0, _textDirection.siteDir)();
        }
      }
    },
    keyUp() {
      if (this.siteSettings.support_mixed_text_direction) {
        let val = this.value;
        if ((0, _textDirection.isRTL)(val)) {
          this.set("dir", "rtl");
        } else if ((0, _textDirection.isLTR)(val)) {
          this.set("dir", "ltr");
        } else {
          this.set("dir", (0, _textDirection.siteDir)());
        }
      }
    },
    placeholder(placeholderKey) {
      return placeholderKey ? _I18n.default.t(placeholderKey) : "";
    }
  }, (_applyDecoratedDescriptor(_obj, "dir", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "dir"), _obj), _applyDecoratedDescriptor(_obj, "placeholder", [_dec], Object.getOwnPropertyDescriptor(_obj, "placeholder"), _obj)), _obj)));
});