define("discourse/plugins/discourse-custom-wizard/discourse/mixins/valid-state", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.States = void 0;
  var _dec, _dec2, _dec3, _obj, _init, _init2, _init3;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const States = _exports.States = {
    UNCHECKED: 0,
    INVALID: 1,
    VALID: 2
  };
  var _default = _exports.default = (_dec = (0, _decorators.default)("_validState"), _dec2 = (0, _decorators.default)("_validState"), _dec3 = (0, _decorators.default)("_validState"), (_obj = {
    _validState: null,
    errorDescription: null,
    init() {
      this._super(...arguments);
      this.set("_validState", States.UNCHECKED);
    },
    valid: state => state === States.VALID,
    invalid: state => state === States.INVALID,
    unchecked: state => state === States.UNCHECKED,
    setValid(valid, description) {
      this.set("_validState", valid ? States.VALID : States.INVALID);
      if (!valid && description && description.length) {
        this.set("errorDescription", description);
      } else {
        this.set("errorDescription", null);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "valid", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "valid"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "invalid", [_dec2], (_init2 = Object.getOwnPropertyDescriptor(_obj, "invalid"), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "unchecked", [_dec3], (_init3 = Object.getOwnPropertyDescriptor(_obj, "unchecked"), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init3;
    }
  }), _obj)), _obj));
});