define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-table-field", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _object, _computed, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("value", "isUser", "isSubmittedAt"), _dec2 = (0, _decorators.default)("field", "value.type"), _dec3 = (0, _decorators.default)("value.type"), _dec4 = (0, _decorators.default)("value"), _dec5 = (0, _decorators.default)("value"), _dec6 = (0, _decorators.default)("value"), _dec7 = (0, _decorators.default)("isUser", "field", "value"), _dec8 = (0, _decorators.default)("username"), _dec9 = (0, _decorators.default)("value"), _dec10 = (0, _decorators.default)("value"), (_obj = {
    classNameBindings: ["value.type"],
    isText: (0, _computed.equal)("value.type", "text"),
    isComposer: (0, _computed.equal)("value.type", "composer"),
    isDate: (0, _computed.equal)("value.type", "date"),
    isTime: (0, _computed.equal)("value.type", "time"),
    isDateTime: (0, _computed.equal)("value.type", "date_time"),
    isNumber: (0, _computed.equal)("value.type", "number"),
    isCheckbox: (0, _computed.equal)("value.type", "checkbox"),
    isUrl: (0, _computed.equal)("value.type", "url"),
    isUpload: (0, _computed.equal)("value.type", "upload"),
    isDropdown: (0, _computed.equal)("value.type", "dropdown"),
    isTag: (0, _computed.equal)("value.type", "tag"),
    isCategory: (0, _computed.equal)("value.type", "category"),
    isGroup: (0, _computed.equal)("value.type", "group"),
    isUserSelector: (0, _computed.equal)("value.type", "user_selector"),
    isSubmittedAt: (0, _computed.equal)("field", "submitted_at"),
    isComposerPreview: (0, _computed.equal)("value.type", "composer_preview"),
    textState: "text-collapsed",
    toggleText: _I18n.default.t("admin.wizard.expand_text"),
    hasValue(value, isUser, isSubmittedAt) {
      if (isUser || isSubmittedAt) {
        return value;
      }
      return value && value.value;
    },
    isUser(field, type) {
      return field === "username" || field === "user" || type === "user";
    },
    isLongtext(type) {
      return type === "textarea" || type === "long_text";
    },
    checkboxValue(value) {
      const isCheckbox = this.get("isCheckbox");
      if (isCheckbox) {
        if (value.value.includes("true")) {
          return true;
        } else if (value.value.includes("false")) {
          return false;
        }
      }
    },
    expandText() {
      const state = this.get("textState");
      if (state === "text-collapsed") {
        this.set("textState", "text-expanded");
        this.set("toggleText", _I18n.default.t("admin.wizard.collapse_text"));
      } else if (state === "text-expanded") {
        this.set("textState", "text-collapsed");
        this.set("toggleText", _I18n.default.t("admin.wizard.expand_text"));
      }
    },
    file(value) {
      const isUpload = this.get("isUpload");
      if (isUpload) {
        return value.value;
      }
    },
    submittedUsers(value) {
      const isUserSelector = this.get("isUserSelector");
      const users = [];
      if (isUserSelector) {
        const userData = value.value;
        const usernames = [];
        if (userData.indexOf(",")) {
          usernames.push(...userData.split(","));
          usernames.forEach(u => {
            const user = {
              username: u,
              url: `/u/${u}`
            };
            users.push(user);
          });
        }
      }
      return users;
    },
    username(isUser, field, value) {
      if (isUser) {
        return value.username;
      }
      if (field === "username") {
        return value.value;
      }
      return null;
    },
    showUsername: (0, _computed.notEmpty)("username"),
    userProfileUrl(username) {
      if (username) {
        return `/u/${username}`;
      }
      return "/";
    },
    categoryUrl(value) {
      const isCategory = this.get("isCategory");
      if (isCategory) {
        return `/c/${value.value}`;
      }
    },
    groupUrl(value) {
      const isGroup = this.get("isGroup");
      if (isGroup) {
        return `/g/${value.value}`;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "hasValue", [_dec], Object.getOwnPropertyDescriptor(_obj, "hasValue"), _obj), _applyDecoratedDescriptor(_obj, "isUser", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isUser"), _obj), _applyDecoratedDescriptor(_obj, "isLongtext", [_dec3], Object.getOwnPropertyDescriptor(_obj, "isLongtext"), _obj), _applyDecoratedDescriptor(_obj, "checkboxValue", [_dec4], Object.getOwnPropertyDescriptor(_obj, "checkboxValue"), _obj), _applyDecoratedDescriptor(_obj, "expandText", [_object.action], Object.getOwnPropertyDescriptor(_obj, "expandText"), _obj), _applyDecoratedDescriptor(_obj, "file", [_dec5], Object.getOwnPropertyDescriptor(_obj, "file"), _obj), _applyDecoratedDescriptor(_obj, "submittedUsers", [_dec6], Object.getOwnPropertyDescriptor(_obj, "submittedUsers"), _obj), _applyDecoratedDescriptor(_obj, "username", [_dec7], Object.getOwnPropertyDescriptor(_obj, "username"), _obj), _applyDecoratedDescriptor(_obj, "userProfileUrl", [_dec8], Object.getOwnPropertyDescriptor(_obj, "userProfileUrl"), _obj), _applyDecoratedDescriptor(_obj, "categoryUrl", [_dec9], Object.getOwnPropertyDescriptor(_obj, "categoryUrl"), _obj), _applyDecoratedDescriptor(_obj, "groupUrl", [_dec10], Object.getOwnPropertyDescriptor(_obj, "groupUrl"), _obj)), _obj)));
});