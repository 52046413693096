define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-selector/wizard-subscription-selector-row", ["exports", "select-kit/components/select-kit/select-kit-row", "discourse-common/utils/decorators"], function (_exports, _selectKitRow, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _selectKitRow.default.extend((_dec = (0, _decorators.default)("item"), (_obj = {
    classNameBindings: ["isDisabled:disabled"],
    isDisabled() {
      return this.item.disabled;
    },
    click(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.item.disabled) {
        this.selectKit.select(this.rowValue, this.item);
      }
      return false;
    }
  }, (_applyDecoratedDescriptor(_obj, "isDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "isDisabled"), _obj)), _obj)));
});