define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-text-editor", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "@ember/runloop", "@ember/component", "I18n"], function (_exports, _decorators, _computed, _wizard, _runloop, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const excludedUserProperties = ["profile_background", "card_background"];
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("forcePreview"), _dec2 = (0, _decorators.default)("showPopover"), _dec3 = (0, _decorators.default)(), _dec4 = (0, _decorators.default)("wizardFields"), _dec5 = (0, _decorators.default)("wizardActions"), (_obj = {
    classNames: "wizard-text-editor",
    barEnabled: true,
    previewEnabled: true,
    fieldsEnabled: true,
    hasWizardFields: (0, _computed.notEmpty)("wizardFieldList"),
    hasWizardActions: (0, _computed.notEmpty)("wizardActionList"),
    didReceiveAttrs() {
      this._super(...arguments);
      if (!this.barEnabled) {
        (0, _runloop.scheduleOnce)("afterRender", () => {
          $(this.element).find(".d-editor-button-bar").addClass("hidden");
        });
      }
    },
    previewLabel(forcePreview) {
      return _I18n.default.t("admin.wizard.editor.preview", {
        action: _I18n.default.t(`admin.wizard.editor.${forcePreview ? "hide" : "show"}`)
      });
    },
    popoverLabel(showPopover) {
      return _I18n.default.t("admin.wizard.editor.popover", {
        action: _I18n.default.t(`admin.wizard.editor.${showPopover ? "hide" : "show"}`)
      });
    },
    userPropertyList() {
      return _wizard.userProperties.filter(f => !excludedUserProperties.includes(f)).map(f => ` u{${f}}`);
    },
    wizardFieldList(wizardFields) {
      return (wizardFields || []).map(f => ` w{${f.id}}`);
    },
    wizardActionList(wizardActions) {
      return (wizardActions || []).map(a => ` w{${a.id}}`);
    },
    actions: {
      togglePreview() {
        this.toggleProperty("forcePreview");
      },
      togglePopover() {
        this.toggleProperty("showPopover");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "previewLabel", [_dec], Object.getOwnPropertyDescriptor(_obj, "previewLabel"), _obj), _applyDecoratedDescriptor(_obj, "popoverLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "popoverLabel"), _obj), _applyDecoratedDescriptor(_obj, "userPropertyList", [_dec3], Object.getOwnPropertyDescriptor(_obj, "userPropertyList"), _obj), _applyDecoratedDescriptor(_obj, "wizardFieldList", [_dec4], Object.getOwnPropertyDescriptor(_obj, "wizardFieldList"), _obj), _applyDecoratedDescriptor(_obj, "wizardActionList", [_dec5], Object.getOwnPropertyDescriptor(_obj, "wizardActionList"), _obj)), _obj)));
});