define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-links", ["exports", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-schema", "@ember/object/computed", "@ember/object", "@ember/component", "@ember/array"], function (_exports, _decorators, _wizard, _wizardSchema, _computed, _object, _component, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj, _init;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("itemType"), _dec2 = (0, _decorators.default)("current", "items.@each.id", "items.@each.type", "items.@each.label", "items.@each.title"), (_obj = {
    classNameBindings: [":wizard-links", "itemType"],
    items: (0, _array.A)(),
    anyLinks: (0, _computed.notEmpty)("links"),
    updateItemOrder(itemId, newIndex) {
      const items = this.items;
      const item = items.findBy("id", itemId);
      items.removeObject(item);
      item.set("index", newIndex);
      items.insertAt(newIndex, item);
    },
    header: itemType => `admin.wizard.${itemType}.header`,
    links(current, items) {
      if (!items) {
        return;
      }
      return items.map((item, index) => {
        if (item) {
          let link = {
            id: item.id
          };
          let label = item.label || item.title || item.id;
          if (this.generateLabels && item.type) {
            label = (0, _wizard.generateName)(item.type);
          }
          link.label = `${label} (${item.id})`;
          let classes = "btn";
          if (current && item.id === current.id) {
            classes += " btn-primary";
          }
          link.classes = classes;
          link.index = index;
          if (index === 0) {
            link.first = true;
          }
          if (index === items.length - 1) {
            link.last = true;
          }
          return link;
        }
      });
    },
    getNextIndex() {
      const items = this.items;
      if (!items || items.length === 0) {
        return 0;
      }
      const numbers = items.map(i => Number(i.id.split("_").pop())).sort((a, b) => a - b);
      return numbers[numbers.length - 1];
    },
    actions: {
      add() {
        const items = this.get("items");
        const itemType = this.itemType;
        let params = (0, _wizardSchema.setWizardDefaults)({}, itemType);
        params.isNew = true;
        params.index = this.getNextIndex();
        let id = `${itemType}_${params.index + 1}`;
        if (itemType === "field") {
          id = `${this.parentId}_${id}`;
        }
        params.id = id;
        let objectArrays = _wizardSchema.default[itemType].objectArrays;
        if (objectArrays) {
          Object.keys(objectArrays).forEach(objectType => {
            params[objectArrays[objectType].property] = (0, _array.A)();
          });
        }
        const newItem = _object.default.create(params);
        items.pushObject(newItem);
        this.set("current", newItem);
      },
      back(item) {
        this.updateItemOrder(item.id, item.index - 1);
      },
      forward(item) {
        this.updateItemOrder(item.id, item.index + 1);
      },
      change(itemId) {
        this.set("current", this.items.findBy("id", itemId));
      },
      remove(itemId) {
        const items = this.items;
        let item;
        let index;
        items.forEach((it, ind) => {
          if (it.id === itemId) {
            item = it;
            index = ind;
          }
        });
        let nextIndex;
        if (this.current.id === itemId) {
          nextIndex = index < items.length - 2 ? index + 1 : index - 1;
        }
        items.removeObject(item);
        if (nextIndex) {
          this.set("current", items[nextIndex]);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "header", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "header"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "links", [_dec2], Object.getOwnPropertyDescriptor(_obj, "links"), _obj)), _obj)));
});