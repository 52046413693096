define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-selector-type", ["exports", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("item.type", "activeType"), (_obj = {
    tagName: "a",
    classNameBindings: ["active"],
    active(type, activeType) {
      return type === activeType;
    },
    click() {
      this.toggle(this.item.type);
    }
  }, (_applyDecoratedDescriptor(_obj, "active", [_dec], Object.getOwnPropertyDescriptor(_obj, "active"), _obj)), _obj)));
});