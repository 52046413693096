define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field", ["exports", "@ember/component", "@ember/string", "discourse-common/utils/decorators", "discourse/lib/text"], function (_exports, _component, _string, _decorators, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj, _init, _init2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("field.type", "field.id"), _dec2 = (0, _decorators.default)("field.id"), _dec3 = (0, _decorators.default)("field.type", "field.id"), _dec4 = (0, _decorators.default)("field.type"), (_obj = {
    classNameBindings: [":wizard-field", "typeClasses", "field.invalid", "field.id"],
    didReceiveAttrs() {
      this._super(...arguments);
      (0, _text.cook)(this.field.translatedDescription).then(cookedDescription => {
        this.set("cookedDescription", cookedDescription);
      });
    },
    typeClasses: (type, id) => `${(0, _string.dasherize)(type)}-field ${(0, _string.dasherize)(type)}-${(0, _string.dasherize)(id)}`,
    fieldClass: id => `field-${(0, _string.dasherize)(id)} wizard-focusable`,
    inputComponentName(type, id) {
      if (["text_only"].includes(type)) {
        return false;
      }
      return (0, _string.dasherize)(type === "component" ? id : `custom-wizard-field-${type}`);
    },
    textType(fieldType) {
      return ["text", "textarea"].includes(fieldType);
    }
  }, (_applyDecoratedDescriptor(_obj, "typeClasses", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "typeClasses"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "fieldClass", [_dec2], (_init2 = Object.getOwnPropertyDescriptor(_obj, "fieldClass"), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "inputComponentName", [_dec3], Object.getOwnPropertyDescriptor(_obj, "inputComponentName"), _obj), _applyDecoratedDescriptor(_obj, "textType", [_dec4], Object.getOwnPropertyDescriptor(_obj, "textType"), _obj)), _obj)));
});