define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-custom-step", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("step.index"), (_obj = {
    classNames: "wizard-custom-step",
    stepConditionOptions(stepIndex) {
      const options = {
        inputTypes: "validation",
        context: "step",
        textSelection: "value",
        userFieldSelection: true,
        groupSelection: true
      };
      if (stepIndex > 0) {
        options["wizardFieldSelection"] = true;
        options["wizardActionSelection"] = true;
      }
      return options;
    },
    actions: {
      bannerUploadDone(upload) {
        this.setProperties({
          "step.banner": upload.url,
          "step.banner_upload_id": upload.id
        });
      },
      bannerUploadDeleted() {
        this.setProperties({
          "step.banner": null,
          "step.banner_upload_id": null
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "stepConditionOptions", [_dec], Object.getOwnPropertyDescriptor(_obj, "stepConditionOptions"), _obj)), _obj)));
});