define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-field-input", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object/computed", "I18n"], function (_exports, _component, _decorators, _computed, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("field.klass"), _dec2 = (0, _decorators.observes)("field.klass"), _dec3 = (0, _decorators.default)("saving", "isExternal", "field.name", "field.klass", "field.type", "field.serializers"), (_obj = {
    tagName: "tr",
    topicSerializers: ["topic_view", "topic_list_item"],
    postSerializers: ["post"],
    groupSerializers: ["basic_group"],
    categorySerializers: ["basic_category"],
    showInputs: (0, _computed.or)("field.new", "field.edit"),
    classNames: ["custom-field-input"],
    loading: (0, _computed.or)("saving", "destroying"),
    destroyDisabled: (0, _computed.alias)("loading"),
    closeDisabled: (0, _computed.alias)("loading"),
    isExternal: (0, _computed.equal)("field.id", "external"),
    didInsertElement() {
      this.set("originalField", JSON.parse(JSON.stringify(this.field)));
    },
    serializerContent(klass) {
      const serializers = this.get(`${klass}Serializers`);
      if (serializers) {
        return serializers.reduce((result, key) => {
          result.push({
            id: key,
            name: _I18n.default.t(`admin.wizard.custom_field.serializers.${key}`)
          });
          return result;
        }, []);
      }
    },
    clearSerializersWhenClassChanges() {
      this.set("field.serializers", null);
    },
    compareArrays(array1, array2) {
      return array1.length === array2.length && array1.every((value, index) => {
        return value === array2[index];
      });
    },
    saveDisabled(saving, isExternal) {
      if (saving || isExternal) {
        return true;
      }
      const originalField = this.originalField;
      if (!originalField) {
        return false;
      }
      return ["name", "klass", "type", "serializers"].every(attr => {
        let current = this.get(attr);
        let original = originalField[attr];
        if (!current) {
          return false;
        }
        if (attr === "serializers") {
          return this.compareArrays(current, original);
        } else {
          return current === original;
        }
      });
    },
    actions: {
      edit() {
        this.set("field.edit", true);
      },
      close() {
        if (this.field.edit) {
          this.set("field.edit", false);
        }
      },
      destroy() {
        this.set("destroying", true);
        this.removeField(this.field);
      },
      save() {
        this.set("saving", true);
        const field = this.field;
        let data = {
          id: field.id,
          klass: field.klass,
          type: field.type,
          serializers: field.serializers,
          name: field.name
        };
        this.saveField(data).then(result => {
          this.set("saving", false);
          if (result.success) {
            this.set("field.edit", false);
          } else {
            this.set("saveIcon", "times");
          }
          setTimeout(() => this.set("saveIcon", null), 10000);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "serializerContent", [_dec], Object.getOwnPropertyDescriptor(_obj, "serializerContent"), _obj), _applyDecoratedDescriptor(_obj, "clearSerializersWhenClassChanges", [_dec2], Object.getOwnPropertyDescriptor(_obj, "clearSerializersWhenClassChanges"), _obj), _applyDecoratedDescriptor(_obj, "saveDisabled", [_dec3], Object.getOwnPropertyDescriptor(_obj, "saveDisabled"), _obj)), _obj)));
});