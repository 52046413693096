define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-composer", ["exports", "discourse-common/utils/decorators", "@ember/object", "@ember/component"], function (_exports, _decorators, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("composer.reply"), _dec2 = (0, _decorators.default)("showPreview"), (_obj = {
    showPreview: false,
    classNameBindings: [":wizard-field-composer", "showPreview:show-preview:hide-preview"],
    didInsertElement() {
      this.set("composer", _object.default.create({
        loading: false,
        reply: this.get("field.value") || ""
      }));
    },
    setField() {
      this.set("field.value", this.get("composer.reply"));
    },
    togglePreviewLabel(showPreview) {
      return showPreview ? "wizard_composer.hide_preview" : "wizard_composer.show_preview";
    },
    actions: {
      togglePreview() {
        this.toggleProperty("showPreview");
      },
      groupsMentioned() {},
      afterRefresh() {},
      cannotSeeMention() {},
      importQuote() {},
      showUploadSelector() {}
    }
  }, (_applyDecoratedDescriptor(_obj, "setField", [_dec], Object.getOwnPropertyDescriptor(_obj, "setField"), _obj), _applyDecoratedDescriptor(_obj, "togglePreviewLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "togglePreviewLabel"), _obj)), _obj)));
});