define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-composer-editor", ["exports", "discourse/components/composer-editor", "discourse-common/utils/decorators", "discourse-common/lib/raw-templates", "@ember/runloop", "discourse/lib/utilities", "discourse/lib/highlight-syntax", "@ember/object/computed", "discourse/models/site", "discourse/lib/uploads", "@ember/string", "discourse/components/modal/insert-hyperlink", "@ember/service"], function (_exports, _composerEditor, _decorators, _rawTemplates, _runloop, _utilities, _highlightSyntax, _computed, _site, _uploads, _string, _insertHyperlink, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const IMAGE_MARKDOWN_REGEX = /!\[(.*?)\|(\d{1,4}x\d{1,4})(,\s*\d{1,3}%)?(.*?)\]\((upload:\/\/.*?)\)(?!(.*`))/g;
  var _default = _exports.default = _composerEditor.default.extend((_dec = (0, _decorators.on)("didInsertElement"), _dec2 = (0, _decorators.default)("field.id"), _dec3 = (0, _decorators.default)(), (_obj = {
    modal: (0, _service.inject)(),
    classNameBindings: ["fieldClass"],
    allowUpload: true,
    showLink: false,
    topic: null,
    showToolbar: true,
    focusTarget: "reply",
    canWhisper: false,
    lastValidatedAt: "lastValidatedAt",
    popupMenuOptions: [],
    draftStatus: "null",
    replyPlaceholder: (0, _computed.alias)("field.translatedPlaceholder"),
    wizardEventFieldId: null,
    composerEventPrefix: "wizard-editor",
    _composerEditorInit() {
      const $input = $(this.element.querySelector(".d-editor-input"));
      if (this.siteSettings.enable_mentions) {
        $input.autocomplete({
          template: (0, _rawTemplates.findRawTemplate)("user-selector-autocomplete"),
          dataSource: term => this._userSearchTerm.call(this, term),
          key: "@",
          transformComplete: v => v.username || v.name,
          afterComplete: value => {
            this.composer.set("reply", value);
            (0, _runloop.scheduleOnce)("afterRender", () => $input.blur().focus());
          },
          triggerRule: textarea => !(0, _utilities.inCodeBlock)(textarea.value, (0, _utilities.caretPosition)(textarea))
        });
      }
      const siteSettings = this.siteSettings;
      if (siteSettings.mentionables_enabled) {
        _site.default.currentProp("mentionable_items", this.wizard.mentionable_items);
        const {
          SEPARATOR
        } = requirejs("discourse/plugins/discourse-mentionables/discourse/lib/discourse-markdown/mentionable-items");
        const {
          searchMentionableItem
        } = requirejs("discourse/plugins/discourse-mentionables/discourse/lib/mentionable-item-search");
        $input.autocomplete({
          template: (0, _rawTemplates.findRawTemplate)("javascripts/mentionable-item-autocomplete"),
          key: SEPARATOR,
          afterComplete: value => {
            this.composer.set("reply", value);
            (0, _runloop.scheduleOnce)("afterRender", () => $input.blur().focus());
          },
          transformComplete: item => item.model.slug,
          dataSource: term => term.match(/\s/) ? null : searchMentionableItem(term, siteSettings),
          triggerRule: textarea => !(0, _utilities.inCodeBlock)(textarea.value, (0, _utilities.caretPosition)(textarea))
        });
      }
      $input.on("scroll", this._throttledSyncEditorAndPreviewScroll);
      this._bindUploadTarget();
      const field = this.field;
      this.editorInputClass = `.${(0, _string.dasherize)(field.type)}-${(0, _string.dasherize)(field.id)} .d-editor-input`;
      this._uppyInstance.on("file-added", () => {
        this.session.set("wizardEventFieldId", field.id);
      });
    },
    fileUploadElementId(fieldId) {
      return `file-uploader-${(0, _string.dasherize)(fieldId)}`;
    },
    allowedFileTypes() {
      return this.siteSettings.authorized_extensions.split("|").map(ext => "." + ext).join(",");
    },
    uploadIcon() {
      return (0, _uploads.uploadIcon)(false, this.siteSettings);
    },
    _handleImageDeleteButtonClick(event) {
      if (!event.target.classList.contains("delete-image-button")) {
        return;
      }
      const index = parseInt(event.target.closest(".button-wrapper").dataset.imageIndex, 10);
      const matchingPlaceholder = this.get("composer.reply").match(IMAGE_MARKDOWN_REGEX);
      this.session.set("wizardEventFieldId", this.field.id);
      this.appEvents.trigger("composer:replace-text", matchingPlaceholder[index], "", {
        regex: IMAGE_MARKDOWN_REGEX,
        index
      });
    },
    actions: {
      extraButtons(toolbar) {
        const component = this;
        if (this.allowUpload && this.uploadIcon) {
          toolbar.addButton({
            id: "upload",
            group: "insertions",
            icon: this.uploadIcon,
            title: "upload",
            sendAction: event => component.send("showUploadModal", event)
          });
        }
        toolbar.addButton({
          id: "link",
          group: "insertions",
          shortcut: "K",
          trimLeading: true,
          unshift: true,
          sendAction: event => component.send("showLinkModal", event)
        });
        if (this.siteSettings.mentionables_enabled) {
          const {
            SEPARATOR
          } = requirejs("discourse/plugins/discourse-mentionables/discourse/lib/discourse-markdown/mentionable-items");
          toolbar.addButton({
            id: "insert-mentionable",
            group: "extras",
            icon: this.siteSettings.mentionables_composer_button_icon,
            title: "mentionables.composer.insert.title",
            perform: () => {
              this.appEvents.trigger("wizard-editor:insert-text", {
                fieldId: this.field.id,
                text: SEPARATOR
              });
              const $textarea = $(document.querySelector(`.composer-field.${this.field.id} textarea.d-editor-input`));
              $textarea.trigger("keyup.autocomplete");
            }
          });
        }
      },
      previewUpdated(preview) {
        (0, _highlightSyntax.default)(preview, this.siteSettings, this.session);
        if (this.siteSettings.mentionables_enabled) {
          const {
            linkSeenMentionableItems
          } = requirejs("discourse/plugins/discourse-mentionables/discourse/lib/mentionable-items-preview-styling");
          linkSeenMentionableItems(preview, this.siteSettings);
        }
        this._super(...arguments);
      },
      showLinkModal(toolbarEvent) {
        let linkText = "";
        this._lastSel = toolbarEvent.selected;
        if (this._lastSel) {
          linkText = this._lastSel.value;
        }
        this.modal.show(_insertHyperlink.default, {
          model: {
            linkText,
            toolbarEvent
          }
        });
      },
      showUploadModal() {
        this.session.set("wizardEventFieldId", this.field.id);
        document.getElementById(this.fileUploadElementId).click();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_composerEditorInit", [_dec], Object.getOwnPropertyDescriptor(_obj, "_composerEditorInit"), _obj), _applyDecoratedDescriptor(_obj, "fileUploadElementId", [_dec2], Object.getOwnPropertyDescriptor(_obj, "fileUploadElementId"), _obj), _applyDecoratedDescriptor(_obj, "allowedFileTypes", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "allowedFileTypes"), _obj), _applyDecoratedDescriptor(_obj, "uploadIcon", [_dec3], Object.getOwnPropertyDescriptor(_obj, "uploadIcon"), _obj), _applyDecoratedDescriptor(_obj, "_handleImageDeleteButtonClick", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_handleImageDeleteButtonClick"), _obj)), _obj)));
});